<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>고객사 관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="스케줄 등록"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>
                            <table class="table-row">
                                <colgroup>
                                    <col width="180">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>일시</th>
                                        <td>
                                            2019-07-01 AM 10:00
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>목적</th>
                                        <td>
                                            오픈
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>고객사</th>
                                        <td>
                                            <span> 당근영어</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>등록자</th>
                                        <td>
                                            <span> Min K(김민정)</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>상세내용</th>
                                        <td>
                                            EAP 제안발표 PT
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <router-link to="/MypimsPersonalityList"><button class="btn-default float-left mt-30">목록</button></router-link>
                            <router-link to="/MypimsPersonalityAdd"><button class="btn-default float-right mt-30">등록</button></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src


export default {
}
</script>

<style lang="scss" scoped>
</style>